 
.v-simple-cropper { 
 .file { 
    display: none; 
 } 
 .v-cropper-layer { 
    position: fixed; 
    top: 0; 
    bottom: 0; 
    left: 0; 
    right: 0; 
    background: #fff; 
    z-index: 99999; 
    display: none; 
 .layer-header { 
  position: absolute;
    left: 0;
    bottom: 0;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 4rem;
    /* padding: 0 0.2rem; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    box-sizing: border-box;
 } 
  .cancel, 
  .confirm { 
     font-size:1rem;
    background: inherit;
    border: 0;
    outline: 0;
    float: left;
    background: rgba(255,255,255,0.8);
    height: 2rem;
    border-radius: 0.3rem;
    width: 5rem;
  } 
  .confirm { 
      float: right; 
  } 
  img { 
    position: inherit!important; 
    border-radius: inherit!important; 
    float: inherit!important; 
  } 
 } 
} 
